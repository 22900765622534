var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("จัดการคำเชิญ")]),_c('v-card',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex pa-4"},[_c('v-btn',{attrs:{"disabled":_vm.datatable.selectedItems.length == 0},on:{"click":function($event){return _vm.deleteMultiple()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ 'name': 'invitation_create', params: { invitation_id: 'create' } })}}},[_vm._v("เพิ่มคำเชิญ")])],1),_c('delay-datatable',{ref:"mainDatatable",attrs:{"show-select":"","headers":_vm.datatable.headers,"url":'/employee/invitation/list',"queryParams":_vm.getDatatableQueryParams,"options":_vm.datatable.options,"isSaveState":true},on:{"update:options":function($event){return _vm.$set(_vm.datatable, "options", $event)},"loadState":_vm.loadDatatableState},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary","ripple":false}},on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false},on:{"input":select},model:{value:(isSelected),callback:function ($$v) {isSelected=$$v},expression:"isSelected"}})]}},{key:"item.expiry_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.converters.convertTimestampToAooDefault(item.expiry_at))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.role ? item.role.name : '-------')+" ")]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.admin ? (item.admin.name + ' ' + item.admin.lastname) : 'ยังไม่มีผู้รับสิทธิ์')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.admin)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showLinkBox(item.uuid)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiLinkVariant))])],1):_vm._e(),(!item.admin)?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.$store.getters['auth/GET_ABILITY'].can('delete', 'Employee')},on:{"click":function($event){return _vm.deleteSingle(item.id, item.uuid)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1):_vm._e()]}}],null,true),model:{value:(_vm.datatable.selectedItems),callback:function ($$v) {_vm.$set(_vm.datatable, "selectedItems", $$v)},expression:"datatable.selectedItems"}})],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.linkReqDialog.isShow),callback:function ($$v) {_vm.$set(_vm.linkReqDialog, "isShow", $$v)},expression:"linkReqDialog.isShow"}},[_c('v-card',[_c('v-card-title',[_vm._v("ลิ้งเชิญ")]),_c('div',{staticClass:"px-4"},[_c('copy-box',{attrs:{"dense":"","outlined":"","value":_vm.linkReqDialog.link}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.linkReqDialog.isShow = false}}},[_vm._v("ปิด")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }