<template>
    <div>
        <h2>จัดการคำเชิญ</h2>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>                    
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'invitation_create', params: { invitation_id: 'create' } })">เพิ่มคำเชิญ</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/employee/invitation/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">            
            <v-simple-checkbox
              v-model="isSelected"
              color="primary"
              @input="select"
              :ripple="false"
            />
          </template>

          <template          
            v-slot:item.expiry_at="{ item }"
          >
            {{ converters.convertTimestampToAooDefault(item.expiry_at) }}
          </template>

          <template          
            v-slot:item.role="{ item }"
          >
            {{ !!item.role ? item.role.name : '-------' }}
          </template>

          <template          
            v-slot:item.admin="{ item }"
          >
            {{ !!item.admin ? (item.admin.name + ' ' + item.admin.lastname) : 'ยังไม่มีผู้รับสิทธิ์' }}
          </template>

          <template          
            v-slot:item.actions="{ item }"
          >
            <v-btn
              v-if="!item.admin"
              icon
              @click="showLinkBox(item.uuid)"
            ><v-icon>{{mdiLinkVariant}}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.uuid)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Employee')"
              v-if="!item.admin"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        

        <v-dialog v-model="linkReqDialog.isShow" max-width="600">
          <v-card>
            <v-card-title>ลิ้งเชิญ</v-card-title>
            <div class="px-4">
              <copy-box
                dense
                outlined
                :value="linkReqDialog.link"
              ></copy-box>
            </div>
            <v-card-actions class="justify-end">
              <v-btn @click="linkReqDialog.isShow = false" color="primary">ปิด</v-btn>
            </v-card-actions>    
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { convertTimestampToAooDefault } from '@/helpers/converter'
import { mdiDeleteOutline, mdiEyeOutline, mdiLinkVariant, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      linkReqDialog: {
        isShow: false,
        link: null,
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'uuid',
            value: 'uuid',
          },
          {
            text: 'กลุ่มสิทธิ์',
            value: 'role',
            sortable: false,
          },
          {
            text: 'หมดอายุ',
            value: 'expiry_at',
          },
          {
            text: 'ผู้รับสิทธิ์',
            value: 'admin',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
      mdiLinkVariant,
      converters: {
        convertTimestampToAooDefault,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  mounted() {
    if (!this.$route.query.uuid) return

    this.showLinkBox(this.$route.query.uuid)
  },
  methods: {
    showLinkBox(uuid) {
      this.linkReqDialog.link = window.location.host + '/join/' + uuid
      this.linkReqDialog.isShow = true
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteMultiple() {
      const invitationNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const invitationIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body:
          'คุณต้องการที่จะลบ "' + invitationNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/employee/invitation', { items: invitationIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/employee/invitation', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>